<template>
  <div>
    <h2>Saturday, October 2nd</h2>
    <v-img
      :lazy-src="require('@/assets/images/Oktoberfest_logo.png')"
      max-height="300"
      max-width="300"
      :src="require('@/assets/images/Oktoberfest_logo.png')"
      class="ma-2"
    ></v-img
    ><v-img
      :lazy-src="require('@/assets/images/Makeawish_logo.png')"
      max-height="300"
      max-width="300"
      :src="require('@/assets/images/Makeawish_logo.png')"
      class="ma-2"
    ></v-img
    ><v-btn href="https://kalensolutions.com/wish-reveal/" target="_blank" color="#fbb040">See the Wish Reveal</v-btn>
  </div>
</template>

<script>
export default {
  name: "Home",
};
</script>
