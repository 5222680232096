<template>
  <v-app>
    <v-main>
      <v-container>
        <v-row class="mx-auto">
          <v-col>
            <router-view />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Menu />
  </v-app>
</template>
<style>
h2 {
  font-family: "Germania One", cursive;
  color: #fbb040;
}
#app {
  background-color: #000;
  text-align: center;
}
</style>
<script>
import Menu from "@/components/Menu.vue";
export default {
  name: "App",
  components: {
    Menu,
  },
  data: () => ({
    //
  }),
};
</script>
