import Vue from "vue";
import Vuex from "vuex";

import * as brew from "@/store/modules/breweries";

Vue.use(Vuex);

export default new Vuex.Store({
    strict: true,

    modules: {
        brew,
    },
});