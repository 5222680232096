import { results } from '../../../data.js'
// import OrgService from "@/services/OrgService";

export const namespaced = true;
if (localStorage.length === 0) {
	localStorage.setItem('brews', JSON.stringify(results))
}
let view = localStorage.getItem('brews')
export const state = {
	brewery: null,
	breweries: JSON.parse(view) || results,
	meta: null,		
	links: null,
	loading: false,
	error: null,
};

export const mutations = {
	SET_BREWERY(state, payload) {
		payload.brewery.map(index => {
			if ( index.objectId === payload.decodedString ) {
				index.checked = true;
			}
		})
		state.breweries = payload.brewery
		localStorage.setItem('brews', JSON.stringify(state.breweries))
	},
	SET_BREWERIES(state, breweries) {
		state.breweries = breweries;
		localStorage.setItem('brews', JSON.stringify(state.breweries))
	},
	SET_ONE(state, payload) {
		state.brewery = payload
	},
	SET_META(state, meta) {
			state.meta = meta;
	},
	SET_LINKS(state, links) {
			state.links = links
	},
	SET_LOADING(state, loading) {
			state.loading = loading;
	},
	SET_ERROR(state, error) {
			state.error = error;
	},
};

export const actions = {
	async getBreweries({ commit }) {
		commit("SET_LOADING", true);
		try {
			const temp = localStorage.getItem('brews')
			commit("SET_BREWERIES", JSON.parse(temp))
			console.log('LS: ', temp)
			commit("SET_LOADING", false);
			console.log('RESPONSE.DATA.BREWERIES: ', results)		
		} catch (error) {
			commit("SET_LOADING", false);
			commit("SET_BREWERIES", null);
		}
	},

	async getBrew({ commit }, decodedString) {
		commit("SET_LOADING", true);
		try {
			const temp = localStorage.getItem('brews')
			commit("SET_BREWERY", { brewery: JSON.parse(temp), decodedString: decodedString })
			commit("SET_LOADING", false);
			console.log('RESPONSE.DATA.BREWERY: ', decodedString)		
		} catch (error) {
			commit("SET_LOADING", false);
			commit("SET_BREWERIES", null);
		}
	},

	async getOne({ commit }, id) {
		commit("SET_LOADING", true);
		try {
			for ( let i = 0; i < state.breweries.length; i++ ) {
				if ( state.breweries[i].objectId === id ) {
					const brew = state.breweries[i];
					commit("SET_ONE", brew)
				}
			}
			commit("SET_LOADING", false);
			console.log('RESPONSE.DATA.ONE: ', results)		
		} catch (error) {
			commit("SET_LOADING", false);
			commit("SET_ONE", null);
		}
	},

};

export const getters = {
	breweries: (state) => {
			return state.breweries;
	},
	meta: (state) => {
			return state.meta;
	},
	links: (state) => {
			return state.links;
	},
	loading: (state) => {
			return state.loading;
	},
	error: (state) => {
			return state.error;
	},
};